<template>
  <div :style='this.returnStyle' class = 'image-wrap'>
    <!-- if the image is not cached or loaded, load placeholder (this will be invisible) -->
    <!-- native lazyloading only for chrome + firefox -->
    <img loading="lazy" :style='this.returnStyle' :class='this.returnClass' @load="handleLoad" :data-src='this.url' :src='this.returnSrc' />
    <transition name = 'loader-fade'>
      <FileLoader v-if='!this.loaded && !this.isCached' />
    </transition>
  </div>
</template>

<script>

import FileLoader from './FileLoader.vue'

export default {
  name: 'SlideImage',
  props: {
    url: String,
    placeholder: String,
    cached: Boolean,
    width: Number,
    height: Number
  },
  data() {
    return {
      loaded: false
    }
  },
  components: {
    FileLoader
  },
  computed: {
    returnSrc: function() {
      const isSafari = window.safari !== undefined
      if (isSafari) {
        return this.cached || this.loaded ? this.url : this.placeholder
      }
      else {
        return this.url 
      }
    },
    returnClass: function() {
      // if image is already cached (global state) or loaded (with callback here) add class
      return this.cached || this.loaded ? 'loaded' : 'loading'
    },
    returnStyle: function() {
      const fileWidth = this.width 
      const fileHeight = this.height 
      const ratio = (fileWidth / fileHeight)
      // 47.5  here should be responsive
      const elHeight = window.innerHeight - 47.5 
      const elWidth = elHeight * ratio 
      // ...parseInt for now
      return 'height:' + ' ' + parseInt(elHeight) + 'px; width:' + ' ' + parseInt(elWidth) + 'px;' 
    }
  },
  methods: {
    handleLoad: function(event) {
      // would be better to do this another way...
      // ensure the callback is for fullsize image only -- once this image has loaded, update local state to add loaded class
      if (event.target.src === this.url) {
        this.loaded = true 
        if (!this.$store.state.cachedImages.includes(this.url)) {
          this.$store.dispatch('cacheImage', this.url)
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";

.image-wrap {
  object-fit: cover;
  pointer-events: none;
}

.image-wrap img {
  height: 100%;
  width: 100%;
  transition: opacity .5s linear;
  object-fit: cover;
  pointer-events: none;
  opacity: 0;
  &.loaded {
    opacity: 1;
  }
}

</style>
