<template>
  <div :style='this.returnStyle' class = 'video-wrap'>    
    <!-- <video style="pointer-events: none;" :data-src='this.url' :src='this.url' poster='null' controls="false" autoplay muted playsinline disableRemotePlayback loop></video> -->
    <video style="pointer-events: none;" :data-src='this.url' src='' poster='null' autoplay muted playsinline disableRemotePlayback loop></video>
    <FileLoader />
  </div>
</template>

<script>

import FileLoader from './FileLoader.vue'

export default {
  name: 'SlideVideo',
  props: {
    url: String,
    height: Number,
    width: Number
  },
  components: {
    FileLoader
  },
  computed: {
    // returnClass: function() {
    //   return this.loaded ? 'loaded' : 'loading'
    // },
    returnStyle: function() {
      const fileWidth = this.width 
      const fileHeight = this.height 
      const ratio = (fileWidth / fileHeight)
      // 47.5  here should be responsive
      const elHeight = window.innerHeight - 47.5 
      const elWidth = elHeight * ratio 
      return 'height:' + ' ' + elHeight + 'px; width:' + ' ' + elWidth + 'px;' 
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";

.video-wrap {
  object-fit: cover;
  pointer-events: none;
  & video {
    pointer-events: none;
    width: 100% !important;
    height: 100% !important;
    opacity: 0;
    transition: opacity .5s linear;
    &::-webkit-media-controls {
      display:none !important;
    }
    &.loaded {
      opacity: 1;
    }
  }
}

</style>
