import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '',
    filter: 'All',
    indexData: {},
    indexProject: {},
    infoData: {},
    mobile: false,
    touch: false,
    windowWidth: 1280,
    windowHeight: 900,
    projectInfo: false,
    showSlides: true,
    slideCount: 1, 
    slideTotal: 1,
    cachedImages: [],
    cursor: '',
    imageView: false,
    sliding: false
  },
  mutations: {
    'SET_TOUCH': (state, payload) => {
      state.touch = true
    },
    'UNSET_TOUCH': (state, payload) => {
      state.touch = false
    },
    'SET_WINDOW_WIDTH': (state, payload) => {
      state.windowWidth = payload 
    },
    'SET_WINDOW_HEIGHT': (state, payload) => {
      state.windowHeight = payload 
    },
    'SET_INDEX_DATA': (state, payload) => {
      state.indexData = payload
    },
    'SET_INDEX_PROJECT': (state, payload) => {
      state.indexProject = payload
    },
    'SET_INFO_DATA': (state, payload) => {
      state.infoData = payload
    },
    'SET_TITLE': (state, payload) => {
      state.title = payload
    },
    'UNSET_TITLE': (state) => {
      state.title = ''
    },
    'RESET_FILTER': (state) => {
      state.filter = 'All'
    },
    'SET_FILTER': (state, payload) => {
      state.filter = payload
    },
    'SHOW_SLIDES': (state, payload) => {
      state.showSlides = true
    },
    'HIDE_SLIDES': (state, payload) => {
      state.showSlides = false
    },
    'SET_SLIDE_TOTAL': (state, payload) => {
      state.slideTotal = payload
    },
    'UNSET_SLIDE_TOTAL': (state) => {
      state.slideTotal = 1 
    },
    'SET_SLIDE_COUNT' : (state, payload) => {
      state.slideCount = payload 
    },
    'RESET_SLIDE_COUNT' : (state) => {
      state.slideCount = 1 
    },
    'INCREMENT_SLIDE_COUNT': (state) => {
      state.slideCount += 1
    },
    'DECREMENT_SLIDE_COUNT': (state) => {
      state.slideCount -= 1
    },
    'SHOW_PROJECT_INFO': (state) => {
      state.projectInfo = true
    },
    'HIDE_PROJECT_INFO': (state) => {
      state.projectInfo = false
    },
    'SET_MOBILE': (state, payload) => {
      state.mobile = true
    },
    'UNSET_MOBILE': (state) => {
      state.mobile = false
    },
    'CACHE_IMAGE': (state, payload) => {
      if (!state.cachedImages.includes(payload)) {
        state.cachedImages.push(payload)
      }
    },
    'SET_SHAPE_CURSOR': (state, payload) => {
      state.cursor = payload
    },
    'UNSET_SHAPE_CURSOR': (state) => {
      state.cursor = ''
    },
    'SET_IMAGE_VIEW': (state) => {
      state.imageView = true
    },
    'UNSET_IMAGE_VIEW': (state) => {
      state.imageView = false
    },
    'SET_SLIDING': (state) => {
      state.sliding = true 
    },
    'UNSET_SLIDING': (state) => {
      state.sliding = false 
    }
  },
  actions: {
    setMobile: (context) => {
      context.commit('SET_MOBILE')
    },
    unsetMobile: (context) => {
      context.commit('UNSET_MOBILE')
    },
    setWindowWidth: (context, payload) => {
      context.commit('SET_WINDOW_WIDTH', payload)
    },
    setWindowHeight: (context, payload) => {
      context.commit('SET_WINDOW_HEIGHT', payload)
    },
    setTouch: (context) => {
      context.commit('SET_TOUCH')
    },
    unsetTouch: (context) => {
      context.commit('UNSET_TOUCH')
    },
    setIndexData: (context, obj) => {
      context.commit('SET_INDEX_DATA', obj)
    },
    setInfoData: (context, obj) => {
      context.commit('SET_INFO_DATA', obj)
    },
    setIndexProject: (context, obj) => {
      context.commit('SET_INDEX_PROJECT', obj)
    },
    setTitle: (context, str) => {
      context.commit('SET_TITLE', str)
    },
    unsetTitle: (context) => {
      context.commit('UNSET_TITLE')
    },
    showProjectInfo: (context, str) => {
      context.commit('SHOW_PROJECT_INFO')
    },
    hideProjectInfo: (context) => {
      context.commit('HIDE_PROJECT_INFO')
    },
    setSlideTotal: (context, n) => {
      context.commit('SET_SLIDE_TOTAL', n)
    },
    unsetSlideTotal: (context) => {
      context.commit('UNSET_SLIDE_TOTAL')
    },
    setSlideCount: (context, n) => {
      context.commit('SET_SLIDE_COUNT', n)
    },
    resetSlideCount: (context) => {
      context.commit('RESET_SLIDE_COUNT')
    },
    incrementSlideCount: (context) => {
      context.commit('INCREMENT_SLIDE_COUNT')
    },
    decrementSlideCount: (context) => {
      context.commit('DECREMENT_SLIDE_COUNT')
    },
    resetFilter: (context) => {
      context.commit('RESET_FILTER')
    },
    setFilter: (context, str) => {
      context.commit('SET_FILTER', str)
    },
    showSlides: (context) => {
      context.commit('SHOW_SLIDES')
    },
    hideSlides: (context) => {
      context.commit('HIDE_SLIDES')
    },
    cacheImage: (context, url) => {
      context.commit('CACHE_IMAGE', url)
    },
    setShapeCursor: (context, payload) => {
      context.commit('SET_SHAPE_CURSOR', payload)
    },
    unsetShapeCursor: (context) => {
      context.commit('UNSET_SHAPE_CURSOR')
    },
    setImageView: (context) => {
      context.commit('SET_IMAGE_VIEW')
    },
    unsetImageView: (context) => {
      context.commit('UNSET_IMAGE_VIEW')
    },
    setSliding: (context) => {
      context.commit('SET_SLIDING')
    },
    unsetSliding: (context) => {
      context.commit('UNSET_SLIDING')
    }
  },
  modules: {
  }
})
