<template>
  <div class = 'video-wrap' :style='this.returnStyle'>
    <video style="pointer-events: none;" class='video-wrap_vid' :data-src='this.url' src='' poster='null' autoplay muted playsinline disableRemotePlayback loop></video>
    <FileLoader />
  </div>
</template>

<script>

import FileLoader from './FileLoader.vue'

export default {
  name: 'VideoComponent',
  props: {
    url: String,
    placeholder: String,
    width: Number,
    height: Number
  },
  data() {
    return {
      loaded: false
    }
  },
  components: {
    FileLoader
  },
  computed: {
    returnStyle: function() {
      let elWidth 
      let elHeight
      const fileWidth = this.width 
      const fileHeight = this.height 
      const ratio = (fileWidth / fileHeight)
      console.log('video ratio', ratio)
      if (ratio > 1) {
        elWidth = window.innerWidth
        elHeight = elWidth * ratio
      }
      else {
        elWidth = window.innerWidth
        elHeight = elWidth / ratio
      }
      // elWidth = window.innerWidth
      // elHeight = elWidth / ratio
      return 'height:' + ' ' + parseInt(elHeight) + 'px; width:' + ' ' + parseInt(elWidth) + 'px;' 
    },
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";



.video-wrap {
  // width: 100%;
  // height: 100%;
  max-width: 100%;
  display: flex;
  position: relative;
  // background: black;
  background: var(--highlight);
  &_vid {
    width: 100%;
    max-width: 100%;
    height: 100%;
    // height: auto;
    // object-fit: cover;
    transition: opacity .5s linear; 
    opacity: 0;
    &::-webkit-media-controls {
      display:none !important;
    }
    &.loaded {
      opacity: 1;
    }
  }
}


</style>
