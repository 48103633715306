<template>
  <div :class="this.returnClass" :style='this.returnStyle'>
    {{ this.returnContent }}
  </div>
</template>

<script>

import Logo from './Logo'

export default {
  name: 'CustomCursor',
  props: {
    routeName: String,
    cursorPos: Object,
    cursor: String,
    windowWidth: Number,
    projectInfo: Boolean
  },
  data() {
    return {
      text: '',
      savedText: '',
      dot: true,
      sqaure: false 
    }
  },
  watch: {
    '$route.name': function(newVal, oldVal) {
      if (newVal.name === 'Info') {
        this.dot = true
      }
    },
    'cursor': function(newVal, oldVal) {
      if (newVal === 'dot') {
        this.dot = true
        this.square = false 
      }
      else if (newVal === 'square') {
        this.square = true 
        this.dot = false 
      }
      else {
        this.square = false 
        this.dot = false 
      }
    },
    'square': function(newVal, oldVal) {
      if (newVal) {
        this.savedText = this.text 
        this.text = ' '
      }
      else {
        this.text = this.savedText
      }
    },
    'dot': function(newVal, oldVal) {
      if (newVal) {
        this.savedText = this.text 
        this.text = ' '
      }
      else {
        this.text = this.savedText
      }
    },
    'cursorPos': function(newVal, oldVal) {
      // if (!this.cursor.length) {
        if (this.$route.name === 'Project') {
          if (newVal.x >= (window.innerWidth / 2)) {
            // this.text = 'NEXT'
            // this.dot ? this.dot = false : false 
            // this.text = ''
            // this.dot = true

            if (this.projectInfo) {
              // win width maps on to width of Slides (set by clip path)
              if (this.windowWidth <= 875) {
                if (newVal.x > (this.windowWidth / 2)) {
                  this.text = ''
                  this.dot = true
                }
                else {
                  this.text = 'NEXT'
                  this.dot ? this.dot = false : false 
                }
                // return this.windowWidth <= 875 ? 'clip-path: inset(0 50vw 0 0 round 0px);' : 'clip-path: inset(0 25vw 0 0 round 0px);' 
              }
              else {
                if (newVal.x > (this.windowWidth * .75)) {
                  this.text = ''
                  this.dot = true
                }
                else {
                  this.text = 'NEXT'
                  this.dot ? this.dot = false : false 
                }
              }
            }
            else {
              this.text = 'NEXT'
              this.dot ? this.dot = false : false 
            }


            // if (window.innerWidth)
          }
          else {
            this.text = 'PREV'
            this.dot ? this.dot = false : false 
          }
        }
        else if (this.$route.name === 'Index') {
          if (newVal.x >= (window.innerWidth / 2)) {
            if (this.text !== 'OPEN') {
              this.text = 'OPEN' 
              this.dot ? this.dot = false : false 
            }
          }
          else {
            this.text = ''
            !this.dot ? this.dot = true : false 
          } 
        }
      }
    // }
  },
  computed: {
    returnContent: function() {
      return this.dot || this.square ? '' : this.text
    },
    returnClass: function() {
      return this.dot ? 'cursor dot' : this.square ? 'cursor square' : 'cursor text'
    },
    returnStyle: function() {
      return 'top:' + ' ' + this.cursorPos.y + 'px;' + 'left:' + ' ' + this.cursorPos.x + 'px'
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";


.cursor {
  position: fixed;
  z-index: 3;
  color: white;
  cursor: none;
  // transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  // @include type-24;
  @include type-16;
  // text-shadow: rgb(90, 90, 90) 1px 0 2px;
  transition: border-radius .25s linear;
  user-select: none;
  &.dot {
    // mix-blend-mode: difference;
    width: 20px;
    height: 20px;
    border-radius: 50% 50%;
    background: white;
  }
  &.square {
    // mix-blend-mode: difference;
    width: 20px;
    height: 20px;
    border-radius: 0 0;
    background: white;
  }
}

</style>
