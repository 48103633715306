export function returnSrc(f, wh) {
  if (f.extension === 'gif') {
    return f.original 
  }
  else {
    var pxRatio = window.devicePixelRatio
    if (pxRatio <= 1) {
      if (wh <= 300) {
        return f.files.thumbs.xs
      }
      else if (wh > 300 && wh <= 600) {
        return f.files.thumbs.sm
      }
      else if (wh > 600 && wh <= 1200) {
        return f.files.thumbs.md
      }
      else if (wh > 1200 && wh <= 1600) {
        return f.files.thumbs.lg
      }
      else if (wh > 1600 && wh <= 2000) {
        return f.files.thumbs.xl
      }
      else if (wh > 2000) {
        return f.files.thumbs.xxl
      }
      else {
        return f.files.thumbs.lg
      }
    }
    // serve x2 images on retina 
    else {
      if (wh <= 300) {
        return f.files.thumbs.sm
      }
      else if (wh > 300 && wh <= 600) {
        return f.files.thumbs.md
      }
      else if (wh > 600 && wh <= 1200) {
        return f.files.thumbs.lg
      }
      else if (wh > 1200 && wh <= 1600) {
        return f.files.thumbs.xl
      }
      else if (wh > 1600 && wh <= 2000) {
        return f.files.thumbs.xxl
      }
      else if (wh > 2000) {
        return f.files.thumbs.xxxl
      }
      else {
        return f.files.thumbs.lg
      }
    }
  }
}