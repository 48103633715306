import { render, staticRenderFns } from "./SlideVideo.vue?vue&type=template&id=4d3d54e4&scoped=true&"
import script from "./SlideVideo.vue?vue&type=script&lang=js&"
export * from "./SlideVideo.vue?vue&type=script&lang=js&"
import style0 from "./SlideVideo.vue?vue&type=style&index=0&id=4d3d54e4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d3d54e4",
  null
  
)

export default component.exports