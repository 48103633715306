<template>
  <div class = 'file-loader'>
    <span>Loading<span id="dotOne">.</span><span id="dotTwo">.</span><span id="dotThree">.</span></span>
  </div>
</template>

<script>

export default {
  name: 'FileLoader',
  computed: {
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";

.file-loader {
  width: 100%;
  height: 100%;
  display: flex;
  // background: black;
  background: var(--highlight);
  // border: solid black 1px;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  // @include type-18;
  @include type-16;
  text-transform: uppercase;
  transition: opacity .5s linear;
  &.hidden {
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#dotOne {
  animation: spinner .75s linear 0s infinite;
}

#dotTwo {
  animation: spinner .75s linear .2s infinite;
}

#dotThree {
  animation: spinner .75s linear .4s infinite;
}

</style>
