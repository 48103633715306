<template>
  <div :class='this.returnClass' :style='this.returnStyle'>
    <img :class='this.returnImgClass' @load="handleLoad" :data-src='this.url' :src='this.isCached || this.loaded ? this.url : this.placeholder' />
    <transition name = 'loader-fade'>
      <FileLoader v-if='!this.loaded && !this.isCached' />
    </transition>
  </div>
</template>

<script>

import FileLoader from './FileLoader'

export default {
  name: 'ImageComponent',
  props: {
    url: String,
    placeholder: String,
    index: Boolean,
    width: Number,
    height: Number
  },
  data() {
    return {
      loaded: false
    }
  },
  components: {
    FileLoader
  },
  mounted() {
    // console.log('this image component', this)
  },
  computed: {
    returnClass: function() {
      return this.index ? 'image-wrap index' : 'image-wrap'
    },
    returnImgClass: function() {
      // if image is already cached (global state) or loaded (with callback here) add class
      if (this.index) {
        return this.isCached || this.loaded ? 'image-wrap_img index loaded' : 'image-wrap_img index loading'
      }
      else {
        return this.isCached || this.loaded ? 'image-wrap_img loaded' : 'image-wrap_img loading'
      }
    },
    isCached: function() {
      return this.$store.state.cachedImages.includes(this.url)
    },
    returnStyle: function() {
      let elWidth 
      let elHeight
      const fileWidth = this.width 
      const fileHeight = this.height 
      const ratio = (fileWidth / fileHeight)
      // 47.5  here should be responsive
      // const elWidth = window.innerWidth
      // const elHeight = window.innerWidth / ratio
      // landscape 
      // if (ratio > 1) {
      //   elWidth = window.innerWidth
      //   elHeight = elWidth / ratio
      // }
      // // portrait
      // else {
      //   console.log('is landscape', this.url)
      //   elWidth = window.innerWidth
      //   elHeight = elWidth / ratio
      // }
      elWidth = window.innerWidth
      elHeight = elWidth / ratio
      // ...parseInt for now
      return 'height:' + ' ' + parseInt(elHeight) + 'px; width:' + ' ' + parseInt(elWidth) + 'px;' 
    },
  },
  methods: {
    handleLoad: function(event) {
      // would be better to do this another way...
      // ensure the callback is for fullsize image only -- once this image has loaded, update local state to add loaded class
      if (event.target.src === this.url) {
        this.loaded = true 
        if (!this.$store.state.cachedImages.includes(this.url)) {
          this.$store.dispatch('cacheImage', this.url)
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";

.image-wrap {
  // width: 100%;
  // height: 100%;
  max-width: 100%;
  display: flex;
  position: relative;
  &.index {
    max-height: calc((100vh - #{$nav-height} * 2) * .8);
    object-position: 50% 50%;
  }
  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;   
    object-position: 50% 50%;

    // turned this off as <Loader /> now fades out
    // transition: opacity .5s linear; 
    opacity: 0;
    // &.index {
    //   max-height: calc((100vh - #{$nav-height} * 2) * .8);
    //   object-position: 50% 50%;
    // }
    &.loaded {
      opacity: 1;
    }
  }
}




</style>
