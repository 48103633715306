<template>
  <div v-if='this.files.length > 0' v-on:click='this.handleClick' :class="returnClass" :style='this.returnStyle'>
    <VueSlickCarousel key='1' class='slide-wrap-inner' ref="carousel" v-bind="this.slickSettings" @beforeChange='handleBeforeChange' @afterChange='handleAfterChange'>
      <figure ref='slide' class = 'slide-wrap-inner_slide' v-for='file in this.files' :key='file.id' :data-type='file.type'>
        <SlideImage :cached='returnCachedStatus(file)' v-if='file.type === "image"' :placeholder='returnImgPlaceholder(file)' :url='returnImgSrc(file)' :width='file.width' :height='file.height' />
        <SlideVideo v-else :width='file.width' :height='file.height' :url='file.file' />
      </figure>
    </VueSlickCarousel>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import SlideImage from './SlideImage.vue'
import SlideVideo from './SlideVideo.vue'
import throttle from 'lodash/throttle'
import { returnSrc } from '../utils/src'


Vue.use(VueLazyload, {
  // changed to false...keep eye on this
  lazyImage: false,
  lazyComponent: true
})

export default {
  name: 'Slides',
  props: {
    mobile: Boolean,
    touch: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    indexData: Array,
    indexProject: Object,
    files: Array,
    slideKey: String,
    projectInfo: Boolean,
    showSlides: Boolean,
    slideTotal: Number,
    slideCount: Number,
    clipWidth: Number,
    variableClipWidth: Boolean,
    cachedImages: Array
  },
  components: {
    VueSlickCarousel,
    SlideImage,
    SlideVideo
  },
  data() {
    return {
      slickSettings: {
        "autoplay": false,
        "dots": false,
        "arrows": false,
        "fade": false,
        "infinite": true,
        "speed": 750,
        "centerMode": false,
        "variableWidth": true,
        "adaptiveHeight": false,
        "slidesToShow": 1,
        "slidesToScroll": 1,  
        "touchMove": false,
        "swipe": false,
        "draggable": false,
        "pauseOnHover": false,
        "swipe": this.handleSwipe
      },
      sliding: false, 
      transitionSpeed: 500,
      slideCount: 1,
      ready: false 
    }
  },
  mounted() {
    console.log('carousel?', this.$refs.carousel)
    if (this.$refs.carousel !== null && this.$refs.carousel !== undefined) {
      this.$refs.carousel.goTo(0)
    }
    const self = this
    setTimeout(() => {
      self.ready = true 
    }, self.transitionSpeed)
  },
  watch: {
    '$route': function(newVal, oldVal) {
      if (newVal.name === 'Project') {
        const self = this 
        const newProject = this.indexData.filter((proj, key) => {
          return proj.slug === newVal.params.project 
        })
        if (newProject !== this.indexProject) {
          this.$store.dispatch('setIndexProject', newProject[0])
        }
        self.ready = false 
        setTimeout(() => {
          self.ready = true 
        }, self.transitionSpeed)
      }
      else {
        // can be temp -- see if fahad feels this is necessary
        const self = this 
        setTimeout(() => {
          if (self.$refs.carousel !== null && self.$refs.carousel !== undefined) {
            self.$refs.carousel.goTo(0)
          }
          // self.$refs.carousel.goTo(0)
        }, 1000)
      }
    },
    'slideCount': function(newVal, oldVal) {
      this.$store.dispatch('setSlideCount', newVal)
    },
    'sliding': function(newVal, oldVal) {
      if (newVal) {
        this.$store.dispatch('setSliding')
      }
      else if (!newVal) {
        this.$store.dispatch('unsetSliding')
      }
    }
  },
  computed: {
    returnKey: function() {
      return !this.imagesLoaded ? 'slides' : 'slides'
    },
    returnClass: function() {
      if (this.$route.name === "Project") {
        if (this.touch || this.mobile) {
          return this.sliding ? 'slide-wrap sliding transition touch' + ' ' + this.$route.name.toLowerCase() : 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase()
        }
        else {
          return this.sliding ? 'slide-wrap sliding transition' + ' ' + this.$route.name.toLowerCase() : 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase()
        }
      }
      else {
        if (!this.variableClipWidth) {
          if (this.touch || this.mobile) {
            return this.showSlides ? 'slide-wrap transition touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap transition touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
          else {
            return this.showSlides ? 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
        }
        else {
          if (this.touch || this.mobile) {
            return this.showSlides ? 'slide-wrap touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
          else {
            return this.showSlides ? 'slide-wrap' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
        }
      }
    },
    returnStyle: function() {
      if (this.$route.name === 'Project') {
        if (this.projectInfo) {
          return this.windowWidth <= 875 ? 'clip-path: inset(0 50vw 0 0 round 0px);' : 'clip-path: inset(0 25vw 0 0 round 0px);' 
        }
        else {
          return 'clip-path: inset(0 0 0 0vw round 0px);'
        }
      }
      else {
        if (!this.touch && !this.mobile) {
          if (this.variableClipWidth) {
            return "clip-path: inset(0 0 0" + " " + this.clipWidth + "px round 0px);"
          }
          else {
            return 'clip-path: inset(0 0 0 50vw round 0px);'
          }
        }
        else {
          return 'clip-path: inset(0 0 0 100vw round 0px);'
        }
      }
    }
  },
  methods: {
    returnCachedStatus: function(f) {
      return this.cachedImages.includes(f.files.thumbs.md) ? true : false
    },
    returnImgPlaceholder: function(f) {
      return f.files.thumbs.xxs
    },
    returnImgSrc: function(f) {
      return returnSrc(f, this.windowHeight)
    },
    // handleBeforeChange: function(prevIndex, newIndex) {
      // this.sliding = true 
      // this.$store.dispatch('setSlideCount', newIndex + 1)
    // },
    // this callback is not working
    // handleAfterChange: function(prevIndex, newIndex) {
      // this.$store.dispatch('setSlideCount', newIndex + 1)
      // this.sliding = false 
    // },
    handleNext() {
      const self = this
      this.$refs.carousel.next()
      this.slideCount === this.files.length ? this.slideCount = 1 : this.slideCount += 1
      this.sliding = true 
      setTimeout(() => {
        self.sliding = false 
        // 850 = slick speed + 100 
      }, 850)
    },
    handlePrev() {
      const self = this 
      this.$refs.carousel.prev()
      this.slideCount === 1 ? this.slideCount = this.files.length : this.slideCount -= 1
      this.sliding = true 
      setTimeout(() => {
        self.sliding = false 
        // 850 = slick speed + 100 
      }, 850)
    },
    handleClick: throttle(function (event) {
      if (this.ready) {
        this.$store.dispatch('setSliding')
        event.pageX > (window.innerWidth / 2) ? this.handleNext() : this.handlePrev()
      }
    }, 750),
  }
}

</script>

<style lang="scss">

@import "../styles/_helpers.scss";

.slide-wrap {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: none !important;
  margin-top: $nav-height;
  height: calc(100% - #{$nav-height});
  width: 100%;
  overflow: hidden;
  z-index: 2;
  display: flex;
  &.touch {
    height: calc(100% - (#{$nav-height} + #{$footer-height}));
  }
  &.transition {
    transition: .5s ease;
  }
  &.project {
    pointer-events: all !important;
  }
  &.project.sliding {
    pointer-events: none !important;
  }
  &-inner {
    transition: transform .25s linear;
    // background: black;
    background: var(--highlight);
    overflow-x: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    // ...temp?
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    pointer-events: none !important;
    &::-webkit-scrollbar {
      display: none;
    }
    &_slide {
      position: relative;
      height: auto !important;
      width: auto !important;
      pointer-events: none !important;
    }
  }
}

.slick-slider {
  touch-action: unset !important;
  pointer-events: none !important;
}

.slick-slider .slick-list {
  pointer-events: none !important;
}

// ...taken from 'vue-slick-carousel/dist/vue-slick-carousel.css'
.slick-track[data-v-e4caeaf8] {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  pointer-events: none !important;
}
.slick-track.slick-center[data-v-e4caeaf8] {
  margin-left: auto;
  margin-right: auto;
  pointer-events: none !important;
}

.slick-track[data-v-e4caeaf8]:after,
.slick-track[data-v-e4caeaf8]:before {
  display: table;
  content: "";
  pointer-events: none !important;
}

.slick-track[data-v-e4caeaf8]:after {
  clear: both;
  pointer-events: none !important;
}

.slick-loading .slick-track[data-v-e4caeaf8] {
  visibility: hidden;
  pointer-events: none !important;
}

.slick-slide[data-v-e4caeaf8] {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  pointer-events: none !important;
}
[dir="rtl"] .slick-slide[data-v-e4caeaf8] {
  float: right;
  pointer-events: none !important;
}

.slick-slide div {
  pointer-events: none !important;
}

.slick-slide img[data-v-e4caeaf8] {
  display: block;
  pointer-events: none !important;
}

.slick-slide.slick-loading img[data-v-e4caeaf8] {
  display: none;
  pointer-events: none !important;
}
.slick-slide.dragging img[data-v-e4caeaf8] {
  pointer-events: none;
  pointer-events: none !important;
}

.slick-initialized .slick-slide[data-v-e4caeaf8] {
  display: block;
  pointer-events: none !important;
}

.slick-loading .slick-slide[data-v-e4caeaf8] {
  visibility: hidden;
  pointer-events: none !important;
}

.slick-vertical .slick-slide[data-v-e4caeaf8] {
  display: block;
  height: auto;
  border: 1px solid transparent;
  pointer-events: none !important;
}

.slick-arrow.slick-hidden[data-v-21137603] {
  display: none;
  pointer-events: none !important;
}

.slick-slider[data-v-3d1a4f76] {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y; */
  -ms-touch-action: none;
  touch-action: none;
  pointer-events: none !important;
  -webkit-tap-highlight-color: transparent;
}

.slick-list[data-v-3d1a4f76] {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  pointer-events: none !important;
}

.slick-list[data-v-3d1a4f76]:focus {
  outline: none;
  pointer-events: none !important;
}

.slick-list.dragging[data-v-3d1a4f76] {
  cursor: pointer;
  cursor: hand;
  pointer-events: none !important;
}


.slides-fade-enter {
  opacity: 0;
}

.slides-enter-active {
  // same speed delay to wait for fade out
  transition: opacity .2s linear;
}

.slides-fade-leave {
  opacity: 0;
}

.slides-fade-leave-active {
  transition: opacity .2s linear .75s;
  z-index: 100;
  opacity: 0;
}


</style>
