<template>
  <header class = "header" v-on:mouseenter='handleMouseEnter' v-on:mouseleave='handleMouseLeave'>
    <nav>
      <div class = 'index-btn' v-on:mouseenter='handleLinkMouseEnter' v-on:mouseleave='handleLinkMouseLeave'>
        <router-link to = '/' class = 'index-btn_logo'>TB.D</router-link>
      </div>

      <transition name = 'fade'>
        <div key='index' class = 'filters' v-if='this.$route.name !== "Project" || this.mobile || this.touch' v-on:mouseenter='handleProjectLinkEnter' v-on:mouseleave='handleProjectLinkLeave'>
          <a v-on:click='handleIndexClick' v-if='!this.showFilters || this.mobile || this.touch' to='/'>Projects</a>
          <div class = 'filters-nav' v-else>
            <div v-on:mouseenter='handleLinkMouseEnter' v-on:mouseleave='handleLinkMouseLeave' :class='this.returnFilterClass("All")' v-on:click='handleFilterClick("All")'>All</div>
            <div v-on:mouseenter='handleLinkMouseEnter' v-on:mouseleave='handleLinkMouseLeave' :class='this.returnFilterClass("Graphic")' v-on:click='handleFilterClick("Graphic")'>Graphic</div>
            <div v-on:mouseenter='handleLinkMouseEnter' v-on:mouseleave='handleLinkMouseLeave' :class='this.returnFilterClass("Interior")' v-on:click='handleFilterClick("Interior")'>Interior</div>
          </div>
        </div>
        <div key='project' class = 'project-header' v-if='this.$route.name === "Project" && !this.mobile && this.$route.name === "Project" && !this.touch && this.title.length'>
          <div class = 'project-header_back-btn'><router-link to = '/'>Index</router-link></div>
          <div class = 'project-header_title'>{{ this.title }}, <span class = 'info-btn' v-on:click='handleProjectInfoClick' v-on:mouseenter='handleLinkMouseEnter' v-on:mouseleave='handleLinkMouseLeave'>{{ this.infoText }}</span></div>
          <div class = 'project-header_count'>{{ this.slideCount }} / {{ this.slideTotal }}</div>
        </div>
      </transition>

      <div class = 'info' v-on:mouseenter='handleLinkMouseEnter' v-on:mouseleave='handleLinkMouseLeave'>
        <router-link to = '/info'>Info</router-link>
      </div>
    </nav>
    
    <!-- mobile filters -->
    <transition name='filter-slide'>
      <div class="touch-filters" v-if='this.touch && this.showFilters || this.mobile && this.showFilters'>
        <div class = 'filters-nav'>
          <div :class='this.returnFilterClass("All")' v-on:click='handleFilterClick("All")'>All</div>
          <div :class='this.returnFilterClass("Graphic")' v-on:click='handleFilterClick("Graphic")'>Graphic</div>
          <div :class='this.returnFilterClass("Interior")' v-on:click='handleFilterClick("Interior")'>Interior</div>
        </div>
        <div v-on:click='handleTouchClose' class = "touch-filters_close">
          <img src = '/images/close.svg' />
        </div>
      </div>
    </transition>


  </header>
</template>

<script>

import Logo from './Logo'

export default {
  name: 'Header',
  props: {
    title: String,
    mobile: Boolean,
    touch: Boolean,
    filter: String,
    projectInfo: Boolean,
    slideCount: Number,
    slideTotal: Number
  },
  data() {
    return {
      showFilters: false
    }
  },
  watch: {
    '$route': function() {
      if (this.$route.name !== 'Index' && this.showFilters) {
        this.showFilters = false
      }
    }
  },
  components: {
    Logo
  },
  computed: {
    infoText: function() {
      return !this.projectInfo ? 'Read More' : 'Read Less'
    }
  },
  methods: {
    returnFilterClass: function(str) {
      return str === this.filter ? 'filters-nav_btn active' : 'filters-nav_btn'
    },
    handleMouseEnter: function(event) {
      if (!this.touch) {
        this.$store.dispatch('setShapeCursor', 'dot')
      }
    },
    handleLinkMouseEnter: function() {
      if (!this.touch) {
        this.$store.dispatch('setShapeCursor', 'square')
      }
    },
    handleLinkMouseLeave: function() {
      if (!this.touch) {
        this.$store.dispatch('setShapeCursor', 'dot')
      }
    },
    handleMouseLeave: function(event) {
      if (!this.touch) {
        if (this.$route.name !== 'Info') {
          if (event.pageY > 1 && event.pageX > 1 && event.pageX <= window.innerWidth) {
            if (!this.mobile) {
              this.$store.dispatch('unsetShapeCursor')
            }
          }
        }
      }
    },
    handleProjectLinkEnter: function() {
      if (!this.touch && !this.mobile) {
        if (this.$route.path === "/") {
          !this.showFilters ? this.showFilters = true : false
        }
        else {
          this.$store.dispatch('setShapeCursor', 'square')
        }
      }
    },
    handleProjectLinkLeave: function() {
      if (!this.touch && !this.mobile) {
        if (this.$route.path === "/") {
          this.$route.path === "/" ? this.showFilters ? this.showFilters = false : false : false
        }
        else {
          this.$store.dispatch('setShapeCursor', 'dot')
        }
      }
    },
    handleIndexClick: function(event) {
      event.preventDefault()
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
      else {
        if (this.touch || this.mobile) {
          this.showFilters = true 
        }
      }
    },
    handleTouchClose: function() {
      this.showFilters = false
    },
    handleProjectInfoClick: function() {
      const self = this 
      !this.projectInfo ? this.$store.dispatch('showProjectInfo') : this.$store.dispatch('hideProjectInfo')
    },
    handleFilterClick: function(str) {
      if (str !== 'all') {
        this.filter === str ? this.$store.dispatch('setFilter', 'All') : this.$store.dispatch('setFilter', str)
      }
      else {
        this.$store.dispatch('setFilter', str)
      }
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";


.header {
  width: 100%;
  height: auto;
  top: 0;
  position: sticky;
  z-index: 2;
  cursor: url('/images/dot-black.svg'), auto !important;
  & nav {
    position: relative;
    border-bottom: solid black 1px;
    height: $nav-height;
    padding: 0 $margin 0 $margin;
    display: flex;
    align-items: center;
    color: black;
    z-index: 3;
    background: white;
    & .filters {
      & a {
        user-select: none;
        cursor: url('/images/square-black.svg'), auto !important;
      }
    }
    & .info {
      & a {
        user-select: none;
        cursor: url('/images/square-black.svg'), auto !important;
      }
      @include type-24_caps;
      right: 0;
      margin-right: $margin;
      position: absolute;
      // @include link-hover;
    }
  }
}

.header nav .index-btn {
  @include type-24_caps;
  & a {
    user-select: none;
    cursor: url('/images/square-black.svg'), auto !important;
  }
  &_back {
    user-select: none;
    display: flex;
    @include type-16;
    align-self: flex-end;
  }
}
 
.filters {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  align-self: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  & a {
    user-select: none;
    @include type-24_caps;
  }
  &-nav {
    padding: 0 1rem 0 1rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    &_btn {
      user-select: none;
      @include type-18_caps;
      cursor: url('/images/square-black.svg'), auto !important;
      margin-right: 3.5rem;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        opacity: .25;
      }
    }
  }
}

.header nav .project-header {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 50vw;
  left: 50%;
  transform: translateX(-50%);
  @include type-16;
  &_back-btn {
    user-select: none;
    transform: translateX(-100%);
    & a {
      user-select: none;
      color: inherit;
    }
  }
  &_title {
    user-select: none;
    flex-grow: 1;
    text-align: center;
    & span {
      @include type-16_italic;
      cursor: url('/images/square-black.svg'), auto !important;
    }
  }
  &_count {
    user-select: none;
    position: relative;
    transform: translateX(100%);
  }
}


.header nav .project-header .info-btn {
  cursor: url('/images/square-black.svg'), auto !important;
}

a.router-link-active {
  cursor: url('/images/square-black.svg'), auto !important;
}


// mobile/touch filters
.touch-filters {
  position: relative;
  width: 100%;
  height: 5rem;
  background: white;
  border-bottom: solid 1px black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $margin 0 $margin;
  z-index: 2;
  &_close {
    position: relative;
    height: 2rem;
    width: 2rem;
    // margin-right: 1rem;
    justify-self: flex-end;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.touch-filters .filters-nav {
  padding: unset;
  justify-content: unset;
  width: unset;
  &_btn {
    margin-right: 2rem;
  }
}

.filter-slide-enter-active {
  transition: transform .25s linear;
}
.filter-slide-leave-active {
  transition: transform .25s linear;
}
.filter-slide-enter, .filter-slide-leave-to {
  transform: translateY(-100%);
}


</style>
