<template>
  <div class = 'page-loader'>
    <span>Loading<span id="dotOne">.</span><span id="dotTwo">.</span><span id="dotThree">.</span></span>
  </div>
</template>

<script>

export default {
  name: 'PageLoader',
  computed: {
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";

.page-loader {
  position: fixed;
  color: black;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
   @include type-24_caps;
  transition: opacity .5s linear;
  &.hidden {
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#dotOne {
  animation: spinner .75s linear 0s infinite;
}

#dotTwo {
  animation: spinner .75s linear .2s infinite;
}

#dotThree {
  animation: spinner .75s linear .4s infinite;
}

</style>
