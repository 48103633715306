import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Project from '../views/Project.vue'
import store from '../store/index'
import isTouchDevice from 'is-touch-device'
import api from '../utils/api'

Vue.use(VueRouter)

// init 
function checkTouch() {
  if (isTouchDevice()) {
    store.dispatch('setTouch')
  }
}

function checkWindow(ww, wh) {
  if (ww <= 576) {
    store.dispatch('setMobile')
    store.dispatch('setWindowWidth', ww)
    store.dispatch('setWindowHeight', wh)
  }
  else {
    store.dispatch('unsetMobile')
    store.dispatch('setWindowWidth', ww)
    store.dispatch('setWindowHeight', wh)
  }
}

function setViewHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

setViewHeight()


// global event listener for window resize
var resizeTimer
window.addEventListener('resize', (event) => {
  setViewHeight()
  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function() {
    checkWindow(window.innerWidth, window.innerHeight)
  }, 250)
})

checkTouch()
checkWindow(window.innerWidth, window.innerHeight)


function handleRouteChange(routeFrom, globalStore, callback) {
  if (routeFrom.name === 'Project') {
    if (globalStore.state.projectInfo) {
      globalStore.dispatch('hideProjectInfo')
      setTimeout(() => {
        callback()
        // ...ensure this is correct speed for transition of clip-path to finish
      }, 500)
    }
    else {
      callback()
    }
  }
  else {
    callback()
  }
}

function handleNotFound() {
  router.push('/')
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: "/projects/:project",
        name: "Project",
        component: () => import('../views/Project.vue'),
      },
    ],
    // todo: fix this (not working)
    // beforeEnter: (to, from, next) => {
    //   if (!Object.keys(store.state.indexData).length > 0) {
    //     api.getPage('home').then((response) => {
    //       store.dispatch('setIndexData', response.data.projects)
    //         if (location.pathname === '/') {
    //           store.dispatch('setIndexProject', response.data.projects[0])
    //         }
    //         if (response.data.highlight_color.length) {
    //           document.documentElement.style.setProperty('--highlight', `${response.data.highlight_color}`);
    //         }
    //         else {
    //           document.documentElement.style.setProperty('--highlight', `#000000`);
    //         }
    //         handleRouteChange(from, store, next)
    //       }).catch(error => {
    //         // return 404 or smtng here
    //         console.log(error)
    //       })
    //     }
    //     else {
    //       handleRouteChange(from, store, next)
    //     }
    //   },
    },
  {
    path: '/info',
    name: 'Info',
    // beforeEnter: (to, from, next) => {
    //   if (!Object.keys(store.state.infoData).length > 0) {
    //     api.getPage('info').then((response) => {
    //       store.dispatch('setInfoData', response.data)
    //       if (response.data.highlight_color.length) {
    //         document.documentElement.style.setProperty('--highlight', `${response.data.highlight_color}`);
    //       }
    //       else {
    //         document.documentElement.style.setProperty('--highlight', `#000000`);
    //       }
    //       handleRouteChange(from, store, next)
    //     }).catch(error => {
    //       // return 404 or smtng here
    //       console.log(error)
    //     })
    //   }
    //   else {
    //     handleRouteChange(from, store, next)
    //   }
    // },
    component: () => import('../views/Info.vue'),
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

// navigation guard seems to work well -> closes project info before redirecting to index (which beforeEnter was not able to do)
router.beforeEach((to, from, next) => {
  console.log('to', to)
  if (to.name === 'Index' || to.name === 'Project') {
    if (!Object.keys(store.state.indexData).length > 0) {
      api.getPage('home').then((response) => {
        store.dispatch('setIndexData', response.data.projects)
        if (location.pathname === '/') {
          store.dispatch('setIndexProject', response.data.projects[0])
        }
        if (response.data.highlight_color.length) {
          document.documentElement.style.setProperty('--highlight', `${response.data.highlight_color}`);
        }
        else {
          document.documentElement.style.setProperty('--highlight', `#000000`);
        }
        handleRouteChange(from, store, next)
      }).catch(error => {
        // return 404 or smtng here
        console.log(error)
      })
    }
    else {
      handleRouteChange(from, store, next)
    }
  }
  else if (to.name === 'Info') {
    if (!Object.keys(store.state.infoData).length > 0) {
      api.getPage('info').then((response) => {
        store.dispatch('setInfoData', response.data)
        if (response.data.highlight_color.length) {
          document.documentElement.style.setProperty('--highlight', `${response.data.highlight_color}`);
        }
        else {
          document.documentElement.style.setProperty('--highlight', `#000000`);
        }
        handleRouteChange(from, store, next)
      }).catch(error => {
        // return 404 or smtng here
        console.log(error)
      })
    }
    else {
      handleRouteChange(from, store, next)
    }
  }
  else {
    handleNotFound()
  }   
})



export default router
